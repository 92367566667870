import { default as _40454nyBPjR4CMeta } from "/home/runner/work/courserev-chat-web-v2/courserev-chat-web-v2/pages/404.vue?macro=true";
import { default as _91threadId_933b14e0wt8wMeta } from "/home/runner/work/courserev-chat-web-v2/courserev-chat-web-v2/pages/chat/[threadId].vue?macro=true";
import { default as errorIwZCSiUPNTMeta } from "/home/runner/work/courserev-chat-web-v2/courserev-chat-web-v2/pages/error.vue?macro=true";
import { default as indexMDH7GqoAPyMeta } from "/home/runner/work/courserev-chat-web-v2/courserev-chat-web-v2/pages/index.vue?macro=true";
import { default as logout6fnHrJJUHaMeta } from "/home/runner/work/courserev-chat-web-v2/courserev-chat-web-v2/pages/logout.vue?macro=true";
export default [
  {
    name: "404",
    path: "/404",
    meta: _40454nyBPjR4CMeta || {},
    component: () => import("/home/runner/work/courserev-chat-web-v2/courserev-chat-web-v2/pages/404.vue").then(m => m.default || m)
  },
  {
    name: "chat-threadId",
    path: "/chat/:threadId()",
    meta: _91threadId_933b14e0wt8wMeta || {},
    component: () => import("/home/runner/work/courserev-chat-web-v2/courserev-chat-web-v2/pages/chat/[threadId].vue").then(m => m.default || m)
  },
  {
    name: "error",
    path: "/error",
    meta: errorIwZCSiUPNTMeta || {},
    component: () => import("/home/runner/work/courserev-chat-web-v2/courserev-chat-web-v2/pages/error.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexMDH7GqoAPyMeta || {},
    component: () => import("/home/runner/work/courserev-chat-web-v2/courserev-chat-web-v2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "logout",
    path: "/logout",
    meta: logout6fnHrJJUHaMeta || {},
    component: () => import("/home/runner/work/courserev-chat-web-v2/courserev-chat-web-v2/pages/logout.vue").then(m => m.default || m)
  }
]